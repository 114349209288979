import React from "react";
import RatingController, { RatingControllerI } from "./Rating.controller";
import { Typography } from "get-life-storybook-ts";
import { translate } from "../../contexts/i18n.context";

interface RatingI extends RatingControllerI {}

const Rating = ({ blogId, totalRating, totalVotes }: RatingI) => {
  const {
    triggerHover,
    triggerVote,
    hoverIndex,
    ratingsCount,
    averageRating,
    userAlreadyVoted,
  } = RatingController({
    blogId,
    totalRating,
    totalVotes,
  });

  return (
    <section className="flex flex-col gap-[8px] text-[#424242] px-content">
      <Typography
        variant="Body"
        weight="700"
        text={translate("rating.title")}
      />

      <div className="w-fit space-y-[8px]">
        <div className="flex flex-row gap-[16px]">
          {Array(5)
            .fill("")
            .map((_, idx) => {
              const value = idx + 1;
              return (
                <StarIcon
                  onMouseEnter={() => triggerHover(idx)}
                  onMouseLeave={() => triggerHover()}
                  onClick={() => triggerVote(value)}
                  isHovered={idx <= hoverIndex}
                  siblingIsHovered={hoverIndex !== undefined}
                  value={value}
                  averageRating={averageRating}
                  className={
                    !userAlreadyVoted ? "cursor-pointer" : "cursor-default"
                  }
                />
              );
            })}
        </div>
        <div
          className="flex flex-row justify-end gap-[16px]"
          itemProp="aggregateRating"
          itemScope
          itemType="https://schema.org/AggregateRating"
        >
          <Typography variant="BodySmall" weight="400" text={""}>
            <span itemProp="ratingValue">{averageRating}</span>/
            <span itemProp="bestRating">5</span>
          </Typography>
          <Typography variant="BodySmall" weight="400" text={""}>
            <span itemProp="reviewCount">{ratingsCount}</span>{" "}
            {translate("rating.votes")}
          </Typography>
        </div>
      </div>
    </section>
  );
};

const StarIcon = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  isHovered,
  siblingIsHovered,
  value,
  averageRating,
  className,
}) => {
  const getIconFillColor = () => {
    const isFilled = value <= averageRating;
    const isHalfFilled = value - 1 < averageRating && value > averageRating;
    if (siblingIsHovered) return "none";
    if (isFilled) return "#3430E3";
    if (isHalfFilled) return "url(#halfFilled)";
    return "none";
  };

  return (
    <span
      role="button"
      aria-label={`Rate this article with ${value} stars`}
      tabIndex={0}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onMouseEnter}
      onBlur={onMouseLeave}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          e.preventDefault();
          onClick();
        }
      }}
      className={className}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fill: isHovered ? "#D6D6F9" : getIconFillColor(),
        }}
      >
        <defs>
          <linearGradient id="halfFilled" x1="0" y1="0.5" x2="1" y2="0.5">
            <stop offset="0%" stop-color="#3430E3" />
            <stop offset="50%" stop-color="#3430E3" />
            <stop offset="50%" stop-color="#ffffff" />
            <stop offset="100%" stop-color="#ffffff" />
          </linearGradient>
        </defs>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 17.75L5.82802 20.995L7.00702 14.122L2.00702 9.25495L8.90702 8.25495L11.993 2.00195L15.079 8.25495L21.979 9.25495L16.979 14.122L18.158 20.995L12 17.75Z"
          stroke="#3430E3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
};

export default Rating;
