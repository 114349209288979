import React from "react";

export interface RatingControllerI {
  blogId: string;
  totalRating: number;
  totalVotes: number;
}

const RatingController = ({ blogId, totalRating, totalVotes }) => {
  const [ratings, setRatings] = React.useState<{
    ratings: number;
    ratingsCount: number;
  }>({
    ratings: parseInt(totalRating),
    ratingsCount: parseInt(totalVotes),
  });

  const updateRatings = (ratings, ratingsCount) =>
    setRatings({
      ratings: parseInt(ratings),
      ratingsCount: parseInt(ratingsCount),
    });

  const getRatingAverage = (rating, votes) => {
    if (rating === 0 || votes === 0) return 0;
    return Math.trunc((rating / votes) * 10) / 10;
  };

  const isArticleAlreadyRatedByUser = () => {
    if (typeof window === "undefined") return;
    const ratedArticles =
      JSON.parse(localStorage.getItem("ratedArticles")) ?? [];
    if (ratedArticles.includes(blogId)) return true;
    return false;
  };

  const [userAlreadyVoted, setUserAlreadyVoted] = React.useState(
    isArticleAlreadyRatedByUser()
  );
  const [hoverIndex, setHoverIndex] = React.useState<number | undefined>(
    undefined
  );

  const triggerHover = (idx?: number | undefined) => {
    if (userAlreadyVoted) return;
    setHoverIndex(idx);
  };

  const triggerVote = async (value: number) => {
    if (userAlreadyVoted) return;
    setUserAlreadyVoted(true);
    triggerHover();
    await getBlogRating();
    fetch(`${process.env.GATSBY_STRAPI_API_URL}/api/blogs/${blogId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          TotalRating: `${ratings.ratings + value}`,
          TotalVotes: `${ratings.ratingsCount + 1}`,
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        const ratedArticles =
          JSON.parse(localStorage.getItem("ratedArticles")) ?? [];
        ratedArticles.push(blogId);
        localStorage.setItem("ratedArticles", JSON.stringify(ratedArticles));

        const TotalRating = result?.data?.attributes?.TotalRating ?? 0;
        const TotalVotes = result?.data?.attributes?.TotalVotes ?? 0;
        updateRatings(TotalRating, TotalVotes);
      })
      .catch((e) => console.log(e));
  };

  const getBlogRating = async () => {
    await fetch(`${process.env.GATSBY_STRAPI_API_URL}/api/blogs/${blogId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const TotalRating = result?.data?.attributes?.TotalRating ?? 0;
        const TotalVotes = result?.data?.attributes?.TotalVotes ?? 0;
        updateRatings(TotalRating, TotalVotes);
      })
      .catch((e) => console.log(e));
  };

  React.useEffect(() => {
    getBlogRating();
  }, []);

  const averageRating = getRatingAverage(ratings.ratings, ratings.ratingsCount);

  return {
    triggerHover,
    triggerVote,
    hoverIndex,
    ratingsCount: ratings.ratingsCount,
    averageRating,
    userAlreadyVoted,
  };
};

export default RatingController;
